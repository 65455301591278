//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/Magento_Wishlist/web/css/module";
//------------------------------------//


body .item-edit-panel__toggle {
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 20px;
    }
}

body .products-grid.wishlist .product.options {
    margin-bottom: 1rem;
}

body .item-edit-panel__content {
    background: white;
}
