@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.sidebar .block-wishlist .subtitle, .sidebar .block-wishlist .product-item-info .action.delete > span, .products-grid.wishlist .comment-box .label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sidebar .block-wishlist .block-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.item-edit-panel__content .product-item-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.sidebar .block-wishlist {
  padding: 1rem;
  border: 1px solid #ced4da;
}

.sidebar .block-wishlist .block-title {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .sidebar .block-wishlist .block-title {
    max-width: 222px;
  }
}

.sidebar .block-wishlist .block-title > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.sidebar .block-wishlist .block-title:hover, .sidebar .block-wishlist .block-title:active, .sidebar .block-wishlist .block-title:focus, .sidebar .block-wishlist .block-title:visited {
  text-decoration: none;
}

.sidebar .block-wishlist .block-title:active {
  outline: none;
}

.sidebar .block-wishlist .block-title::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.sidebar .block-wishlist .block-title.disabled, .sidebar .block-wishlist .block-title[disabled],
fieldset[disabled] .sidebar .block-wishlist .block-title {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.sidebar .block-wishlist .block-title:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.sidebar .block-wishlist .block-title span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sidebar .block-wishlist .block-title:before {
  margin-right: 0.5rem;
}

.sidebar .block-wishlist .product-items {
  margin-bottom: 1rem;
}

.sidebar .block-wishlist .product-items > .product-item {
  width: 100%;
}

.sidebar .block-wishlist .actions-toolbar {
  margin-bottom: 0;
}

.sidebar .block-wishlist .action.details {
  font-size: 0.75rem;
}

.sidebar .block-wishlist .product-item-info {
  position: relative;
}

.sidebar .block-wishlist .product-item-info::after {
  display: block;
  clear: both;
  content: "";
}

.sidebar .block-wishlist .product-item-info .product-item-photo {
  float: left;
  width: 75px;
}

.sidebar .block-wishlist .product-item-info .product-item-details {
  float: left;
  width: calc(100% - 110px);
  padding-left: 0.5rem;
}

.sidebar .block-wishlist .product-item-info .product-item-link,
.sidebar .block-wishlist .product-item-info .price-box .price {
  font-size: 0.75rem;
  line-height: 1.33;
}

.sidebar .block-wishlist .product-item-info .action.tocart {
  padding: 0.5rem 0.7rem;
  font-size: 10px;
  line-height: 1.33;
}

.sidebar .block-wishlist .product-item-info p.price-as-configured {
  margin-bottom: 0;
}

.sidebar .block-wishlist .product-item-info .product-item-link > span {
  display: block;
}

.sidebar .block-wishlist .product-item-info .action.delete {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  z-index: 2;
}

.sidebar .block-wishlist .product-item-info .action.delete:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.sidebar .block-wishlist .product-item-info .action.delete:before {
  color: #b70e0e;
}

.sidebar .block-wishlist .product-item-info .action.delete:after {
  display: none;
}

.wishlist-index-index .sidebar .block-wishlist {
  display: none;
}

.products-grid.wishlist .product-item-info .product-item-inner {
  position: relative;
  transform: translateY(0%);
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  opacity: 1;
  visibility: visible;
}

.products-grid.wishlist .action.edit {
  display: none !important;
}

.products-grid.wishlist .product-item-actions {
  margin-top: 0;
}

.products-grid.wishlist .product-item-name {
  margin-top: auto;
}

@media screen and (min-width: 768px) {
  .products-grid.wishlist .price-box,
  .products-grid.wishlist .product-item-name {
    padding: 0 1rem;
  }
}

.products-grid.wishlist .action.delete {
  padding: 0;
}

@media screen and (min-width: 430px) {
  .products-grid.wishlist .action.delete {
    padding: 8px;
  }
}

.products-grid.wishlist .action.delete > span {
  font-size: 12px;
}

.products-grid.wishlist .action.delete > span:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.products-grid.wishlist .action.delete > span:before {
  position: relative;
  top: 3px;
  color: #b70e0e;
  margin-right: 0.25rem;
}

.products-grid.wishlist .action.delete:after {
  display: none;
}

.form-wishlist-items > .actions-toolbar .primary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.form-wishlist-items > .actions-toolbar .primary .action.update,
.form-wishlist-items > .actions-toolbar .primary .action.share,
.form-wishlist-items > .actions-toolbar .primary .action.tocart {
  margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
  .shared.wishlist .product-item {
    max-width: 235px;
  }
}

body.wishlist-shared-index .page-main {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1115px;
}

@media screen and (min-width: 992px) {
  body.wishlist-shared-index .page-main {
    border: 0 none;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (min-width: 992px) {
  body.wishlist-shared-index .page-main .form-wishlist-items > .actions-toolbar .primary > .tocart {
    margin-left: auto;
  }
}

body.wishlist-shared-index .page-main .page-title > span {
  font-size: 20px;
}

@media screen and (min-width: 0px) {
  body.wishlist-shared-index .page-main .page-title > span {
    font-size: calc(20px + 19 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  body.wishlist-shared-index .page-main .page-title > span {
    font-size: 39px;
  }
}

body.wishlist-shared-index .page-main ol.product-items {
  margin-bottom: 0;
}

.wishlist-index-index .products-grid.wishlist .action.towishlist,
.wishlist-index-index .products-grid.wishlist .action.delete,
.wishlist-shared-index .products-grid.wishlist .action.towishlist,
.wishlist-shared-index .products-grid.wishlist .action.delete {
  max-width: none;
}

.wishlist-index-index .products-grid.wishlist .action.towishlist,
.wishlist-shared-index .products-grid.wishlist .action.towishlist {
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .wishlist-index-index .products-grid.wishlist .product-items > .product-item {
    width: 50%;
  }
}

@media screen and (max-width: 767.98px) {
  .wishlist-index-index .products-grid.wishlist .product-items > .product-item {
    z-index: auto;
  }
}

@media screen and (max-width: 767.98px) {
  .wishlist-index-index .products-grid.wishlist .product-items > .product-item:nth-child(odd) .mage-dropdown-dialog {
    right: auto;
    left: 0;
  }
}

.wishlist-index-index .products-grid.wishlist .product-item-info {
  overflow: visible;
}

@media screen and (max-width: 767.98px) {
  .wishlist-index-index .products-grid.wishlist .product-item-info {
    position: initial;
  }
}

.wishlist-index-index .products-grid.wishlist .product-item-info .product-item-inner {
  padding: 1rem;
}

.form-wishlist-items .wishlist .product.options {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.form-wishlist-items .wishlist .product.options::after {
  display: block;
  clear: both;
  content: "";
}

.form-wishlist-items .wishlist .product.options ul.dropdown {
  position: absolute;
  z-index: 1060;
  display: none;
  width: 100%;
  margin-top: 10px;
  padding: 0;
  border: 1px solid #adb5bd;
  background: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  top: auto;
  left: 0;
}

.form-wishlist-items .wishlist .product.options ul.dropdown:after, .form-wishlist-items .wishlist .product.options ul.dropdown:before {
  position: absolute;
  content: '';
  transform: scaleX(1.4);
}

.form-wishlist-items .wishlist .product.options ul.dropdown:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 10.5px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  z-index: 3;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -10px;
}

.form-wishlist-items .wishlist .product.options ul.dropdown:after {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 10.5px solid #adb5bd;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: scaleX(1.4) translateY(1px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -11px;
}

.form-wishlist-items .wishlist .product.options ul.dropdown > li {
  padding: 0.5em;
}

.form-wishlist-items .wishlist .product.options ul.dropdown > li:focus, .form-wishlist-items .wishlist .product.options ul.dropdown > li:hover {
  background-color: #dddcdb;
  cursor: pointer;
}

.form-wishlist-items .wishlist .product.options ul.dropdown > li:first-child {
  margin-top: 1px;
}

.form-wishlist-items .wishlist .product.options.active {
  overflow: visible;
}

.form-wishlist-items .wishlist .product.options.active ul.dropdown {
  display: block;
}

.form-wishlist-items .wishlist .product.options .toggle {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-appearance: none;
  white-space: normal;
  padding-right: 9px;
}

.form-wishlist-items .wishlist .product.options .toggle:after {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  content: "";
  font-family: "ms-icons";
  font-size: 10px;
  line-height: 20px;
  transform-origin: 50% 50%;
  transform-origin: 10px 10px;
  transform: rotate(0deg) translateX(0) translateY(0);
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.form-wishlist-items .wishlist .product.options.active .toggle {
  background: #e9ecef;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.form-wishlist-items .wishlist .product.options.active .toggle:after {
  transform: rotate(180deg) translateX(0) translateY(0);
}

.form-wishlist-items .wishlist .product.options[data-collapsible] .toggle {
  font-size: 0.75rem;
}

.form-wishlist-items .wishlist .product.options .content {
  padding: 0.5rem;
}

@media screen and (max-width: 767.98px) {
  .form-wishlist-items .options[data-collapsible] .content dl dd,
  .form-wishlist-items .options[data-collapsible] .content dl dt {
    width: 100%;
    float: none;
  }
}

.wishlist-shared-index .wishlist-comment {
  display: none;
}

.touch .products-grid .product-item.is-active .product-item-info {
  box-shadow: none !important;
  border: 0 none;
}

.touch .products-grid .product-item.is-active .product-item-info .product-item-photo {
  transform: none !important;
}

.wishlist .text-muted {
  color: #767676;
}

.item-edit-panel__toggle {
  display: block;
  width: 100%;
  max-width: none;
  font-size: 75%;
}

.item-edit-panel__toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.item-edit-panel__toggle:before {
  position: relative;
  margin-right: 6px;
}

.item-edit-panel .mage-dropdown-dialog {
  position: absolute;
  top: 0;
  right: 0;
  animation: u-animation-fade 0.225s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  width: 100%;
  height: 100% !important;
  background: #fff;
  padding: 1rem 0;
  z-index: 3;
  border: 1px solid rgba(221, 220, 219, 0.5);
  box-shadow: 0 14px 56px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.07);
}

@media screen and (max-width: 767.98px) {
  .item-edit-panel .mage-dropdown-dialog {
    width: 200%;
    height: auto !important;
    min-height: 100% !important;
  }
}

.item-edit-panel__content {
  height: 100%;
  min-height: 368px;
}

.item-edit-panel__content .product-item-inner {
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: 368px;
}

.item-edit-panel__content .product-item-inner > * {
  background: #fff;
}

.item-edit-panel__content .field.comment-box {
  flex: 1 1 auto;
  width: 100%;
}

.item-edit-panel__content .product-item-comment {
  width: 100%;
  height: 100% !important;
  min-height: 100px;
  font-size: 0.75rem;
  resize: none;
}

.item-edit-panel__content .control {
  height: 100%;
}

.item-edit-panel .product-item-name {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 36px !important;
}

@media screen and (max-width: 767.98px) {
  .item-edit-panel .product-item-name {
    font-size: 0.75rem !important;
  }
}

.item-edit-panel .ui-dialog-buttonpane {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
}

.item-edit-panel .ui-dialog-buttonpane .action.close {
  width: 36px;
  height: 36px;
  padding: 10px;
  background: #fff;
  cursor: pointer;
}

.item-edit-panel .ui-dialog-buttonpane .action.close:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.item-edit-panel .ui-dialog-buttonpane .action.close:hover {
  opacity: 0.7;
}

.item-edit-panel .ui-dialog-buttonpane .action.close .ui-button-text {
  display: none;
}

body .item-edit-panel__toggle {
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 992px) {
  body .item-edit-panel__toggle {
    font-size: 20px;
  }
}

body .products-grid.wishlist .product.options {
  margin-bottom: 1rem;
}

body .item-edit-panel__content {
  background: white;
}
