.item-edit-panel {
  &__toggle {
    @include ms-icon(edit);
    display: block;
    width: 100%;
    max-width: none;
    font-size: 75%;

    &:before {
      position: relative;
      margin-right: 6px;
    }
  }

  .mage-dropdown-dialog {
    @include absolute(0, 0);
    animation: u-animation-fade $transition-duration-ease-in $transition-timing-ease-in-out-quad;
    width: 100%;
    height: 100% !important;
    background: $white;
    padding: spacers(3) 0;
    z-index: z('above') + 1;
    border: 1px solid rgba($border-color, 0.5);
    box-shadow: 0 14px 56px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.07);

    @include respond-to-down(md, $breakpoints, true) {
      width: 200%;
      height: auto !important;
      min-height: 100% !important;
    }
  }

  &__content {
    height: 100%;
    min-height: 368px;

    .product-item-inner {
      @extend %u-flex-col-xcenter-ycenter;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      height: 100%;
      min-height: 368px;

      > * {
        background: $white;
      }
    }

    .field.comment-box {
      flex: 1 1 auto;
      width: 100%;
    }

    .product-item-comment {
      width: 100%;
      height: 100% !important;
      min-height: 100px;
      font-size: $font-size-xs;
      resize: none;
    }

    .control {
      height: 100%;
    }
  }

  .product-item-name {
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 36px !important;

    @include respond-to-down(md, $breakpoints, true) {
      font-size: $font-size-xs !important;
    }
  }

  .ui-dialog-buttonpane {
    position: absolute;
    top: 0;
    right: 0;
    z-index: z('above') + 2;

    .action.close {
      @include ms-icon('x');
      @include size(36px);
      padding: 10px;
      background: $white;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      .ui-button-text {
        display: none;
      }
    }
  }
}
